<template>
  <section ref="parent">
    <a-list item-layout="horizontal" :data-source="list || []" :split="false" size="small" class="approval-list">
      <template #renderItem="{ item }">
        <a-list-item @click="checked(item)">
          <template #actions>
            <i class="iconfont icon-cha" @click="onChangeList(item)" style="color: #cccccc" v-if="type != 1" />
            <CheckOutlined v-if="item.check" style="color: #c3161c; vertical-align: top; font-size: 16px" />
          </template>
          <a-list-item-meta>
            <template #title>
              <span class="approval-name" :title="item.label || item.name" v-if="type != 1" style="width: 200px">{{
                item.label || item.name
              }}</span>
              <template v-else>
                <p style="display: flex;align-items: center">
                  <span
                    class="approval-name"
                    :title="item.label || item.name"
                    :style="item.name.length > 5 ? 'width:90px' : ''"
                    >{{ item.label || item.name }}</span
                  >
                  （{{ item.mobile || '' }}）
                </p>
              </template>
            </template>
            <template #avatar>
              <img
                :src="item.avatarId ? item.fullAvatar : require('@/assets/images/default-avatar.png')"
                class="approval-img"
              />
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>
  </section>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { CheckOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  name: 'approval-staff-List',
  props: ['list', 'onChangeList', 'type'],
  components: {
    CheckOutlined
  },
  setup(props, cxt) {
    const { emit } = cxt
    const state = reactive({
      checkStaff: {}
    })
    const checked = row => {
      if (props.type != 1) {
        return
      }
      state.checkStaff = row
      emit('checkRemote', state.checkStaff)
      props.list.forEach(item => {
        console.log('props.list对象的值', item)
        if (item.id === row.value) {
          item.check = true
        } else {
          item.check = false
        }
      })
    }
    onMounted(() => {
      console.log('接收的list的值', props.list)
    })
    return {
      checked,
      ...toRefs(state)
    }
  }
})
</script>

<style lang="scss" scoped>
.approval-list {
  .approval-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    transform: translateY(0px);
    vertical-align: top;
    margin-right: 0;
  }
  .approval-name {
    display: inline-block;
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px;
    // width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
:deep(.ant-list-item-meta-title) {
  margin-bottom: 0;
}
:deep(.ant-list-sm .ant-list-item) {
  padding: 0 0 16px 0;
}
:deep(.ant-list-item-meta-avatar) {
  margin-right: 12px;
}
:deep(.ant-list-item-action) {
  margin-left: 0;
}
:deep(.ant-list-empty-text) {
  transform: translateY(60%);
}
</style>
